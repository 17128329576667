import React from "react";
import { Link } from "gatsby";

import Button from "src/components/Button/button";
import {
  StyledMainContainer,
} from "src/components/Careers/LookingForAnIntership/lookingForAnIntershipStyle";
import { ResponsiveColumnContainer } from "src/styles/globalStyle";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

interface ILookingForAnIntershipProps {
  internships: string[];
}

const LookingForAnIntership = ({ internships }: ILookingForAnIntershipProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  return (
    <StyledMainContainer>
      <ResponsiveColumnContainer $maxGap={120} $minGap={32}>
        <h1>{"Looking for an\ninternship"}</h1>
        {isMediumScreen && (
          <Link to={`/internship`}>
            <Button
              text="Let's talk"
              fontSize={36}
              mobileFontSize={24}
              centerContent
            />
          </Link>
        )}
      </ResponsiveColumnContainer>

      <ul>
        {internships?.map((item, i) => (
          <li key={`${item}${i}`}>{item}</li>
        ))}
      </ul>

      {!isMediumScreen && (
          <Link to={`/internship`}>
            <Button
              text="Let's talk"
              fontSize={36}
              centerContent
            />
          </Link>
        )}
    </StyledMainContainer>
  );
};

export default React.memo(LookingForAnIntership);
