import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { initiativeListLeft, initiativeListRight } from "utility/lists";
import Initiative from "../Initiative/initiative";

import {
  StyledWebInitiativesRow,
  StyledWebMainContainer,
  StyledWebContainer,
  StyledWebReferenceItem,
  StyledWebReferencesWrapper,
  StyledMobileMainContainer,
  StyledWebReferencesRowContainer,
  StyledWebReferencesColContainer,
  StyledWebReference,
  StyledInitiativesWrapper,
  StyledJapaneseSymbolBigContainer,
  StyledRowContainer,
} from "./initiativesListStyle";

import JapaneseSymbolBig from "images/japaneseSymbolBig.svg";
import JapaneseSymbolSmall from "images/japaneseSymbolSmall.svg";
import { ColumnContainer } from "src/styles/globalStyle";

const InitiativesList = (): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  const [hoveredInitiativeId, setHoveredInitiativeId] = useState<number | null>(null);

  return isMediumScreen ? (
    <StyledWebMainContainer>
      <h2>Initiatives we take pride in.</h2>
      <StyledInitiativesWrapper>
        <StyledWebContainer>
          <ul style={{ flex: 1 }}>
            {[...initiativeListLeft, ...initiativeListRight].map((item) => item?.title ? (
              <StyledWebInitiativesRow
                key={`${item.title}`}
                onMouseOver={() => setHoveredInitiativeId(item.id)}
                onMouseLeave={() => setHoveredInitiativeId(null)}
              >
                <Initiative
                  title={item.title}
                  isSelected={hoveredInitiativeId === item.id}
                />
              </StyledWebInitiativesRow>
            ) : null)}
          </ul>

        <StyledWebReferencesRowContainer>
          <StyledWebReferencesColContainer>
            <StyledWebReference>
              References
            </StyledWebReference>

            <StyledWebReferencesWrapper>
              {initiativeListLeft.map((item) => (
                <StyledWebReferenceItem
                  key={`${item.references}`}
                  $isSelected={hoveredInitiativeId === item.id}
                >
                  {item.references}
                </StyledWebReferenceItem>
              ))}
            </StyledWebReferencesWrapper>
          </StyledWebReferencesColContainer>

          <StyledWebReferencesWrapper>
            {initiativeListRight.map((item) => (
              <StyledWebReferenceItem
                key={`${item.references}`}
                $isSelected={hoveredInitiativeId === item.id}
              >
                {item.references}
              </StyledWebReferenceItem>
            ))}
          </StyledWebReferencesWrapper>

          </StyledWebReferencesRowContainer>

          <StyledJapaneseSymbolBigContainer>
            <JapaneseSymbolBig />
          </StyledJapaneseSymbolBigContainer>
        </StyledWebContainer>
      </StyledInitiativesWrapper>
    </StyledWebMainContainer>
  ) : (
    <div>
      <h2>{"Initiatives\nwe take\npride in."}</h2>
      <StyledMobileMainContainer>
        <ul>
          {[...initiativeListLeft, ...initiativeListRight].map((item) => item?.title ? (
            <StyledWebInitiativesRow
              key={`${item.title}`}
            >
              <Initiative
                title={item.title}
                isSelected
                key={`${item.title}`}
              />
            </StyledWebInitiativesRow>
          ) : null)}
        </ul>



        <StyledRowContainer>
          <ColumnContainer>
            <StyledWebReference>
              References
            </StyledWebReference>

            <StyledWebReferencesWrapper>
              {[...initiativeListLeft, ...initiativeListRight].map((item) => (
                <StyledWebReferenceItem
                  key={`${item.references}`}
                  $isSelected
                >
                  {item.references}
                </StyledWebReferenceItem>
              ))}
            </StyledWebReferencesWrapper>
          </ColumnContainer>

          <JapaneseSymbolSmall />
        </StyledRowContainer>

      </StyledMobileMainContainer>
    </div>
  );
};

export default React.memo(InitiativesList);
