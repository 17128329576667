import styled, { keyframes } from "styled-components";
import { theme } from "utility/theme";
import { sizes } from "utility/device";
import { MAX_VIEWPORT_WIDTH } from "utility/constants";

interface ICustomPropStyle {
  propName: string;
  maxSize: number;
  minSize: number;
}

export const genCustomPropsStyle = ({ props }: { props: ICustomPropStyle[] }): string => `
  ${props.map(({ propName, maxSize, minSize }) => `
    ${propName}: max(${maxSize / MAX_VIEWPORT_WIDTH * 100}vw, ${minSize}px);
  `).join(" ")}

  @media all and (max-width: ${sizes.m}) {
    ${props.map(({ propName, minSize }) => `
      ${propName}: ${minSize}px;
  `).join(" ")}
  }

  @media all and (min-width: ${sizes.xl}) {
    ${props.map(({ propName, maxSize }) => `
      ${propName}: ${maxSize}px;
  `).join(" ")}
  }
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div<{ $borderColor?: string }>`
  width: 66px;
  height: 66px;
  border-radius: 50%;
  border: ${({ theme, $borderColor = theme.colors.lightGray2 }) => `6px solid ${$borderColor}`};
  border-top: 6px solid ${theme.colors.black};
  animation: ${spinAnimation} 1s linear infinite;
`;

export const ContentFrame = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 4.167vw;
  border: 2px solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  min-width: 300px;
  ${genCustomPropsStyle({
    props: [
      { propName: "padding", maxSize: 80, minSize: 40 },
      { propName: "border-radius", maxSize: 80, minSize: 40 }
    ]
  })}
`;

export const underlineAnimation: string = `
  background: linear-gradient(to right, transparent, transparent),
  linear-gradient(to right, black, black);
  background-size: 100% 2px, 0 2px;
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s;
  text-decoration: none;
  
  &:hover {
    background-position: bottom right;
    background-size: 0 2px, 100% 2px;
  }
`;

interface ISizeStyle {
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

export const genSizeStyle = ({
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
}: ISizeStyle): string => `
  width: max(${maxWidth / MAX_VIEWPORT_WIDTH * 100}vw, ${minWidth}px);
  height: max(${maxHeight / MAX_VIEWPORT_WIDTH * 100}vw, ${minHeight}px);
  max-width: ${maxWidth}px;
  max-height: ${maxHeight}px;

  @media all and (min-width: ${sizes.xl}) {
    width: ${maxWidth}px;
    height: ${maxHeight}px;
  }
`;

interface ITextStyle {
  maxFontSize: number;
  minFontSize: number;
  maxLineHeight: number;
  minLineHeight: number;
  isCustomised?: boolean;
}

export const genTextStyle = ({
  maxFontSize,
  minFontSize,
  maxLineHeight,
  minLineHeight,
  isCustomised = false,
}: ITextStyle): string => `
  font-size: ${maxFontSize}px;
  line-height: ${maxLineHeight}px;
  white-space: pre-wrap;
  font-weight: 400;
  font-style: normal;

  ${!isCustomised && `
    margin: 0;
    padding: 0;
    color: ${theme.colors.black};
  `}

  @media (max-width: ${sizes.xl}) {
    font-size: max(${(maxFontSize / MAX_VIEWPORT_WIDTH * 100)}vw, ${minFontSize}px);
    line-height: max(${maxLineHeight / MAX_VIEWPORT_WIDTH * 100}vw, ${minLineHeight}px);
  }
`;

export const genCustomPropStyle = ({ propName, maxSize, minSize }: ICustomPropStyle): string => `
  ${propName}: max(${maxSize / MAX_VIEWPORT_WIDTH * 100}vw, ${minSize}px);

  @media all and (min-width: ${sizes.xl}) {
    ${propName}: ${maxSize}px;
  }
`;

interface ITextLimitStyle {
  maxLinesNum: number;
  breakPoints?: { [key: string]: number };
}

export const genTextLimit = ({ maxLinesNum, breakPoints }: ITextLimitStyle): string => `
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${maxLinesNum};
  white-space: pre-wrap;

  ${breakPoints && Object.keys(breakPoints ?? []).map((breakPoint) => `
    @media (max-width: ${breakPoint}) {
      -webkit-line-clamp: ${breakPoints[breakPoint]};
    }
  `)}
`;

export const StyledParagraph = styled.p`
  ${genTextStyle({
    maxFontSize: 26,
    minFontSize: 18,
    maxLineHeight: 23.4,
    minLineHeight: 33.8,
  })}
`;

export const StyledLabel = styled.label`
  ${genTextStyle({
    maxFontSize: 24,
    minFontSize: 14,
    maxLineHeight: 28.8,
    minLineHeight: 16.8,
  })}
`;

export const ColumnContainerBig = styled.p`
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media all and (max-width: ${sizes.m}) {
    gap: 32px;
  }
`;

export const ColumnContainerMedium = styled.p`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const SectionWrapper = styled.div<{
  minPaddingVertical?: number;
  maxPaddingVertical?: number;
  showSeparator?: boolean;
}>`
  width: 100%;
  ${({ minPaddingVertical = 60, maxPaddingVertical = 80 }) => `
    ${genCustomPropsStyle({
      props: [
        { propName: "padding-top", maxSize: maxPaddingVertical, minSize: minPaddingVertical },
        { propName: "padding-bottom", maxSize: maxPaddingVertical, minSize: minPaddingVertical },
    ]})}
  `}
  ${({ showSeparator, theme }) => showSeparator && `border-bottom: 2px solid ${theme.colors.black};`}
`;

export const StaticSectionWrapper = styled.div<{
  padding?: number;
  showSeparator?: boolean;
}>`
  width: 100%;
  padding-top: ${({ padding = 60 }) => padding}px;
  padding-bottom: ${({ padding = 60 }) => padding}px;
  ${({ showSeparator, theme }) => showSeparator && `border-bottom: 2px solid ${theme.colors.black};`}
`;

export const ResponsiveColumnContainer = styled.div<{ $maxGap?: number;  $minGap?: number }>`
  display: flex;
  flex-direction: column;
  ${({ $maxGap = 60, $minGap = 30 }) => `
    ${genCustomPropStyle({
      propName: "gap",
      maxSize: $maxGap,
      minSize: $minGap,
    })}
  `}
`;

export const ColumnContainer = styled.div<{ $gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap = 60 }) => $gap}px;
`;

export const PageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 120,
    minSize: 60,
  })}
`;

export const MediumHeading = styled.div`
  ${genTextStyle({
    maxFontSize: 64,
    maxLineHeight: 76.8,
    minFontSize: 32,
    minLineHeight: 38.4,
    isCustomised: false,
  })}
  font-family: Moderat-Regular, Arial, Helvetica, sans-serif;
`;
