import styled, { keyframes } from 'styled-components';
import { MAX_VIEWPORT_WIDTH } from 'utility/constants';
import { sizes } from 'utility/device';

interface ISizeProps {
  $maxWidth: number;
  $maxHeight: number;
  $minWidth: number;
  $minHeight: number;
}

const rotate = keyframes`
  0%, 16.67% { transform: rotateX(0deg); }
  20.83%, 37.5% { transform: rotateX(90deg); }
  41.67%, 58.33% { transform: rotateX(180deg); }
  62.5%, 79.17% { transform: rotateX(270deg); }
  83.33%, 100% { transform: rotateX(360deg); }
`;

export const Container = styled.span`
  perspective: 1000px;
  position: relative;
  display: inline-block;
`;

export const Rectangle = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  animation: ${rotate} 24s infinite linear;
`;

export const Mask = styled.div`
  opacity: 0;
`;

export const Side = styled.div<{ $background: string }>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${({ theme, $background = theme.colors.homeBackground }) => $background};
  backface-visibility: hidden;
`;

export const Front = styled(Side)<ISizeProps>`
  transform: rotateX(0deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / MAX_VIEWPORT_WIDTH * 50}vw, ${$minHeight / 2}px`}));

  @media all and (min-width: ${sizes.xl}) {
    transform: rotateX(0deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / 2}px, ${$minHeight / 2}px`}));
  }
`;

export const Back = styled(Side)<ISizeProps>`
  transform: rotateX(180deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / MAX_VIEWPORT_WIDTH * 50}vw, ${$minHeight / 2}px`}));

  @media all and (min-width: ${sizes.xl}) {
    transform: rotateX(180deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / 2}px, ${$minHeight / 2}px`}));
  }
`;

export const Top = styled(Side)<ISizeProps>`
  transform: rotateX(90deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / MAX_VIEWPORT_WIDTH * 50}vw, ${$minHeight / 2}px`}));

  @media all and (min-width: ${sizes.xl}) {
    transform: rotateX(90deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / 2}px, ${$minHeight / 2}px`}));
  }
`;

export const Bottom = styled(Side)<ISizeProps>`
  transform: rotateX(-90deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / MAX_VIEWPORT_WIDTH * 50}vw, ${$minHeight / 2}px`}));

  @media all and (min-width: ${sizes.xl}) {
    transform: rotateX(-90deg) translateZ(max(${({ $maxHeight, $minHeight }) => `${$maxHeight / 2}px, ${$minHeight / 2}px`}));
  }
`;
