import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { ButtonType } from "utility/theme";
import { IBlog } from "utility/models";
import { Loader, PageContainer, ResponsiveColumnContainer } from "src/styles/globalStyle";
import Footer from "src/components/Footer/footer";
import { StyledSeparator } from "src/components/Layout/layoutStyle";
import Button from "src/components/Button/button";
import { BlogCard } from "src/components/Blog/BlogCard/blogCard";
import {
  BlogsWrapper,
  ContentContainer,
  LoaderWrapper,
  Separator,
} from "./blogStyle";
import { HeadingAndSubheading } from "./Heading/heading";
import { SubscribeForm } from "components/SubscribeForm/subscribeForm";
import { navigate } from "gatsby";
import routes from "utility/routes";
import SectionAnimation from "components/SectionAnimation/sectionAnimation";

interface BlogPropsI {
  blogs: Array<IBlog>;
  loading: boolean;
  title?: string | null;
  isError: boolean;
  tag?: string | null;
}

const blogSubheading =
  "Written by the people of LambdaWorks, sharing their thoughts on engineering, design, and management.";
const errorHeading = "Sorry something went wrong this time...";
const errorSubheading =
  "It appears that we’ve run into a problem, you can refresh the page and try again.";
const buttonText = "Reload page";

export const Blog = ({
  blogs,
  loading,
  title,
  isError,
  tag,
}: BlogPropsI): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.midS;

  const blogHeading =
    title ||
    (isMobile
      ? "Learn\nwhat drives\nLambdaWorks"
      : "Learn what\ndrives LambdaWorks");
  const heading = isError ? errorHeading : blogHeading;
  const subheading = isError ? errorSubheading : blogSubheading;

  return (
    <PageContainer>
      <ContentContainer>
        <SectionAnimation>
          <ResponsiveColumnContainer $maxGap={32} $minGap={32}>
            <HeadingAndSubheading
              heading={heading}
              subheading={subheading}
            />
            {!!tag && (
              <Button
                text="Clear filter"
                iconInFront
                arrowType="left"
                onClick={() => navigate(routes.BLOG)}
                isfullwidth={isMobile}
                justifyContent={isMobile ? "center" : "space-between"}
                fontSize={24}
                mobileFontSize={24}
              />
            )}
          </ResponsiveColumnContainer>
        </SectionAnimation>

        {!isError && (
          <SectionAnimation>
            <ResponsiveColumnContainer>
              <SubscribeForm />
              <Separator />
            </ResponsiveColumnContainer>
          </SectionAnimation>
        )}


        {blogs?.map((blog, i) => (
          <BlogsWrapper $isError={isError}>
            <SectionAnimation forceAnimation={!i}>
              <>
                <BlogCard blog={blog} />
                {i < blogs?.length - 1 && (
                  <Separator />
                )}
              </>
            </SectionAnimation>
          </BlogsWrapper>
        ))}
        {isError && !loading && (
          <Button
            text={buttonText}
            arrowType={null}
            fontSize={36}
            mobileFontSize={24}
            justifyContent="center"
            type={ButtonType.BLACK}
            onClick={() => window.location.reload()}
          />
        )}
        {loading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
      </ContentContainer>
      <SectionAnimation>
        <ResponsiveColumnContainer>
          <StyledSeparator />
          <Footer />
        </ResponsiveColumnContainer>
      </SectionAnimation>
    </PageContainer>
  );
};
