import React from 'react';
import {
  Back,
  Container,
  Front,
  Top,
  Rectangle,
  Bottom,
  Mask,
} from './cubeSpinnerStyle';

interface ICubeSpinner {
  display: [string, string, string, string];
  background: string;
  maxWidth: number;
  maxHeight: number;
  minWidth: number;
  minHeight: number;
}

const CubeSpinner = ({
  display,
  background,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
}: ICubeSpinner) => {
  const styleProps = {
    $background: background,
    $maxWidth: maxWidth,
    $maxHeight: maxHeight,
    $minWidth: minWidth,
    $minHeight: minHeight,
  }
  return (
    <Container>
      <Rectangle>
        <Front {...styleProps}>{display[0]}</Front>
        <Back {...styleProps}>{display[1]}</Back>
        <Top {...styleProps}>{display[2]}</Top>
        <Bottom {...styleProps}>{display[3]}</Bottom>
      </Rectangle>
      <Mask>awesome</Mask>
    </Container>
  )
};

export default CubeSpinner;
