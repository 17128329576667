import styled from "styled-components";

import { sizes } from "utility/device";
import { genSizeStyle, genTextStyle } from "src/styles/globalStyle";

const BREAK_POINT = 776;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  padding-bottom: 60px;

   @media (max-width: ${BREAK_POINT}px) {
    padding-top: 60px;
  }
`;

export const StyledTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 120px;

  @media (max-width: ${BREAK_POINT}px) {
    display: none;
  }
`;

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 6.3vw 0;

  @media (min-width: ${sizes.xl}) {
    border-spacing: 122px 0;
  }
`;

export const StyledProjectImage = styled.div<{ url: string; }>`
  ${genSizeStyle({
    maxWidth: 536,
    maxHeight: 596,
    minWidth: 335,
    minHeight: 335,
  })}
  background-image: ${({ url }) => `url(/${url})`};
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  border-radius: 40px;

  @media (max-width: ${sizes.mediumS}) {
    width: 335px;
    height: 335px;
    max-height: 335px;
  }
`;

export const StyledTexttContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  gap: 40px;

  @media (max-width: ${sizes.m}) {
    margin-top: 1vw;
  }
`;

export const StyledProjectName = styled.div`
  ${genTextStyle({
    maxFontSize: 36,
    maxLineHeight: 39.6,
    minFontSize: 22,
    minLineHeight: 24,
    isCustomised: false,
  })}

  color: rgba(0, 0, 0, 0.50);
  font-family: Moderat-Regular;
  font-style: normal;
  font-weight: 400;
`;

export const StyledProjectDescription = styled.div<{ $maxWidth?: number }>`
  font-family: Moderat-Regular;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: ${({ $maxWidth = 731 }) => $maxWidth}px;

  ${genTextStyle({
    maxFontSize: 24,
    maxLineHeight: 28.8,
    minFontSize: 18,
    minLineHeight: 23.4,
  })}
`;

export const StyledMobileContainer = styled.div`
  display: none;

  @media (max-width: ${BREAK_POINT}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 32px;
    gap: 32px;
  }
`;

export const StyledMobileProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
