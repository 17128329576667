import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import routes from "utility/routes";

import {
  StyledAgreeRow,
  StyledCookieMainContainer,
  StyledCookieParagraph,
  StyledCookieRow,
  StyledDismissParagraph,
  StyledInnerContainer,
  Item,
  RadioButton,
  RadioButtonLabel,
  StyledRadioButtonParagraph,
} from "./cookieConsentStyle";

const COOKIE_CONSENT_KEY = "cookieConsent";

interface CookieProps {
  getCookieVisibility?: (a: boolean) => void;
}

const CookieConsent = ({
  getCookieVisibility
}: CookieProps): JSX.Element => {
  const [select, setSelect] = useState("noAgreement");
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleSelectChange = (event: any) => {
    const value = event.target.value;
    setSelect(value);
    localStorage.setItem(COOKIE_CONSENT_KEY, "yes");
    setIsVisible(false);
  };

  const handleDismiss = () => {
    setIsVisible(false);
    sessionStorage.setItem(COOKIE_CONSENT_KEY, "yes");
  };

  useEffect(() => {
    if(getCookieVisibility)
    getCookieVisibility(isVisible);
  }, [isVisible]);

  useEffect(() => {
    var cookieConsent =
      localStorage.getItem(COOKIE_CONSENT_KEY) ??
      sessionStorage.getItem(COOKIE_CONSENT_KEY);
    cookieConsent !== null ? setIsVisible(false) : setIsVisible(true);
  }, []);

  return (
    <StyledCookieMainContainer $isVisible={isVisible}>
      <StyledInnerContainer>
        <StyledCookieParagraph>
          By using our site you agree to our{" "}
          <Link
            to={routes.PRIVACY_POLICY}
            aria-label={routes.PRIVACY_POLICY}
          >
            use of cookies policy
          </Link>{" "}
          in order to deliver a better site experience.
        </StyledCookieParagraph>
        <StyledCookieRow>
          <StyledDismissParagraph onClick={() => handleDismiss()}>
            Dismiss
          </StyledDismissParagraph>
          <StyledAgreeRow>
            <Item>
              <RadioButton
                type="radio"
                name="radio"
                value="agree"
                checked={select === "agree"}
                onChange={(event) => handleSelectChange(event)}
              />
              <RadioButtonLabel />
              <StyledRadioButtonParagraph>I agree</StyledRadioButtonParagraph>
            </Item>
          </StyledAgreeRow>
        </StyledCookieRow>
      </StyledInnerContainer>
    </StyledCookieMainContainer>
  );
};

export default React.memo(CookieConsent);
