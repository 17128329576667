import React, { useEffect, useState } from "react";
import { config, useSpring } from "@react-spring/web";
import { Link } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { cursorPosition } from 'src/types/mouse.types'
import { PROJECT_POSTER_MAP } from 'utility/projects'
import Button from "src/components/Button/button";
import routes from "utility/routes";
import ImageHoverIndicator from "src/components/ImageHoverIndicator/imageHoverIndicator";
import SectionTitle from "components/SectionTitle/sectionTitle";
import {
  isTouchDevice,
  setViewportListener,
  mapStaticImgList,
  findStaticImg,
} from "utility/functions";
import { theme } from "utility/theme";
import { clientList } from "utility/lists";
import { ClientProps } from "utility/models";
import {
  clientListAnimationDuration,
  clientSliderInterval,
} from "utility/constants";
import ArrowRight from "src/images/arrowRight.svg";

import {
  ColumnContainer,
  ExpanderStyled,
  LinkWrapper,
  ListInnerWrapper,
  StyledClientContainer,
  StyledClientHeroContainer,
  StyledClientHeroTitle,
  StyledClientItem,
  StyledClientList,
  StyledClientListContainer,
  StyledClientListImage,
  StyledClientListTitle,
  StyledClientListTitleMobile,
  StyledClientMiddleContainer,
  StyledViewProjectCircle,
  StyledViewProjectIndicator,
  StyledViewProjectText,
} from "./clientListStyle";

const PROJECTS_NUM_DESKTOP = 8;
const PROJECTS_NUM_MOBILE = 5;

interface ClientListProps {
  title?: string | JSX.Element;
  showSVG?: boolean;
  revealAnimationColor?: string;
  clientList?: ClientProps[];
  ctaButton?: boolean;
  viewOurWork?: boolean;
  onClientChangeCallback?: (index: number) => void;
}

const defaultProps: ClientListProps = {
  title: "Your tech partner in creating\noutstanding digital products.",
  showSVG: true,
  revealAnimationColor: theme.colors.homeBackground,
  ctaButton: false,
  viewOurWork: true,
};

const ClientList = (props: ClientListProps): JSX.Element => {
  const imagePaths = mapStaticImgList("projects", PROJECT_POSTER_MAP);

  const [hidden, setHidden] = useState<boolean>(true);
  const [position, setPosition] = useState({ x: -1, y: -1 });
  const [cursor, setCursor] = useState<string>("auto");

  const fadeAnimation = useSpring({
    config: { ...config.stiff, duration: clientListAnimationDuration / 2 },
    from: { opacity: 0 },
    to: { opacity: 1 },
    reset: false,
  });

  const breakpoints = useBreakpoint();
  const isMobile = !breakpoints.m;

  const onMouseOver = (): void => {
    if (!isTouchDevice && hidden) {
      setHidden(false);
      setCursor("none");
    }
  };

  const onMouseLeave = (): void => {
    if (!isTouchDevice && !hidden) {
      setHidden(true);
      setCursor("auto");
    }
  };

  const minList = (props.clientList || clientList).slice(0, PROJECTS_NUM_DESKTOP);

  const [currentClient, setCurrentClient] = useState(0);
  const [selectClient, setSelectedClient] = useState(minList[0]);
  const [selectedImage, setSelectedImage] = useState(minList[0].img);
  const [isSliderAutomatic, setSliderAutomatic] = useState(true);

  useEffect(() => {
    setViewportListener("clientCircle");
  }, []);

  useEffect(() => {
    const clientInterval = setTimeout(() => {
      if (isSliderAutomatic) {
        const nextIndex =
          currentClient === minList.length - 1 ? 0 : currentClient + 1;
        setCurrentClient(nextIndex);
        onClientChange(minList[nextIndex]);
      }
    }, clientSliderInterval);

    return () => {
      clearTimeout(clientInterval);
    };
  }, [currentClient, isSliderAutomatic, minList?.length]);

  const onClientChange = (item: ClientProps): void => {
    if (item?.index !== selectClient?.index) {
      setTimeout(() => setSelectedClient(item), 0);
      setTimeout(() => setSelectedImage(item.img), 0);
    }
  };

  const handleMouseMove = (position: cursorPosition): void => {
    setPosition(position);
  }

  const onClientPress = (client: any, condition: boolean): void => {
    setSliderAutomatic(false);
    condition && onClientChange(client);
  };

  const cursorMoved = !(position.x < 0 && position.y < 0)
  const cursorStyle = cursorMoved ? cursor : 'auto'

  return (
    <StyledClientContainer>
      <StyledClientHeroContainer>
        <StyledClientHeroTitle>
          <SectionTitle text={props.title ?? defaultProps.title ?? ""}/>
          {props.ctaButton && (
            <Link to={routes.CONTACT}>
            <div style={{ marginTop: "4vw" }}>
              <Button
                text="Let's talk"
                fontSize={36}
              />
            </div>
            </Link>
          )}
        </StyledClientHeroTitle>
      </StyledClientHeroContainer>

      <StyledClientMiddleContainer>
        <StyledClientListContainer>

          <LinkWrapper to={selectClient.route}>
            <StyledClientListTitleMobile>
              Case studies
            </StyledClientListTitleMobile>
            <StyledClientListImage
              src={findStaticImg(imagePaths, selectedImage)}
              style={{ ...fadeAnimation, cursor: cursorStyle }}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
            >
              <StyledClientListTitle>
                Case studies
              </StyledClientListTitle>
            </StyledClientListImage>
          </LinkWrapper>

          <ImageHoverIndicator
            hidden={hidden || !cursorMoved}
            onMouseMove={handleMouseMove}
          />

          <StyledClientList>
          <ListInnerWrapper>
            {(isMobile ? minList?.slice(0, PROJECTS_NUM_MOBILE) : minList).map((item) => {
              return (
                <StyledClientItem
                  to={item.route}
                  selected={item.name === selectClient.name}
                  onMouseOver={() => onClientPress(item, !isTouchDevice)}
                  onTouchStart={() => onClientPress(item, isTouchDevice)}
                  key={item.name}
                >
                  <h5>{item.name}</h5>
                  {!isMobile && selectClient.index === item.index &&
                    <StyledViewProjectIndicator>
                      <StyledViewProjectText>
                        View Project
                      </StyledViewProjectText>
                      <StyledViewProjectCircle />
                    </StyledViewProjectIndicator>
                  }
                  {isMobile && (
                    <ArrowRight />
                  )}
                </StyledClientItem>
              );
            })}
          </ListInnerWrapper>
          {props?.viewOurWork && (
            <ColumnContainer>
              <ExpanderStyled to={routes.WORK}>
                View our work
              </ExpanderStyled>
            </ColumnContainer>
          )}
        </StyledClientList>
        </StyledClientListContainer>
      </StyledClientMiddleContainer>
    </StyledClientContainer>
  );
};

export default ClientList;
