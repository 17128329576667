import styled from "styled-components";
import { sizes, specificBreakpoints } from "utility/device";
import LWLogo from "src/images/logoFooterMobile.svg";
import { Link } from "gatsby";
import { genCustomPropStyle, genCustomPropsStyle, genSizeStyle, genTextStyle } from "src/styles/globalStyle";

export const StyledFooter = styled.div`
  ${genCustomPropsStyle({
    props: [
      { propName: "padding-bottom", maxSize: 64, minSize: 21 }
    ]
  })}
`;

export const StyledMidContencWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;

  ${genCustomPropStyle({
    propName: "margin-top",
    maxSize: 100,
    minSize: 32,
  })}

  @media all and (max-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledContactInfo = styled.h3`
  @media all and (max-width: ${sizes.mediumS}) {
    display: none;
  }
`;

export const StyledMedRowWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
  width: 100%;
`;

export const StyledMedColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledLinkGrid = styled.h5`
  display: grid;
  grid-template-columns: auto auto auto;
  row-gap: 0;
  height: auto;
  margin: auto 0;
  column-gap: 40px;

  @media all and (max-width: ${sizes.m}) {
    width: 100%;
  }
`;

export const StyledLogo = styled(LWLogo)<{ isHidden?: boolean }>`
  flex-shrink: 1;
  position: absolute;
  right: -6.4rem;
  ${genSizeStyle({
    maxWidth: 774,
    maxHeight: 350,
    minWidth: 413,
    minHeight: 350,
  })}
  ${({ isHidden }) => isHidden && "display: none;"};

  @media (min-width: 1776px) {
    right: -116px;
  }
`;

export const StyledLogoSmall = styled.div<{ $isHidden?: boolean }>`
  display: flex;
  flex-shrink: 1;
  ${({ $isHidden }) => $isHidden && "display: none;"}
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1;
  gap: 20px;

  ${genCustomPropStyle({
    propName: "margin-top",
    maxSize: 60,
    minSize: 32,
  })}

  @media all and (max-width: ${sizes.m}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media all and (max-width: ${sizes.mediumS}) {
    margin-top: 0px;
  }
`;

export const StyledBottomInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media all and (max-width: ${specificBreakpoints.footerItemsMobile}) {
    gap: 30px;
    flex-direction: column;
  }

  @media all and (max-width: ${sizes.minS}) {
    justify-content: flex-start;
  }
`;

export const StyledBottomTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  justify-self: end;

  @media all and (max-width: ${sizes.m}) {
    justify-content: space-between;
    width: 100%;
  }

  @media all and (max-width: 370px) {
    flex-direction: column-reverse !important;
    gap: 20px;
    justify-content: flex-start;
    align-items:  flex-start;
  }
`;

export const StyledExternalLinks = styled.div<{ mode: "light" | "dark" }>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  color: ${({ theme, mode }) => mode === "dark" ? theme.colors.black : theme.colors.white};
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 60,
    minSize: 4,
  })}

  @media all and (max-width: ${sizes.minS}) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
`;

export const StyledAClass = styled.a<{ mode: "light" | "dark" }>`
  background: linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, black, black);
  background-size: 100% 2px, 0 2px;
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s;
  text-decoration: none;
  ${genTextStyle({
    maxFontSize: 36,
    maxLineHeight: 39.6,
    minFontSize: 20,
    minLineHeight: 24,
  })}

  &:hover {
    background-position: bottom right;
    background-size: 0 2px, 100% 2px;
  }

  color: ${({ mode, theme }) => mode === "dark" ? theme.colors.black : theme.colors.white};
`;
export const StyledLinkExternal = styled.a`
  white-space: nowrap;
  display: flex;

  ${genTextStyle({
  maxFontSize: 36,
  minFontSize: 22,
  maxLineHeight: 39.6,
  minLineHeight: 24,
})}

  width: fit-content;
  background: linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, black, black);
  background-size: 100% 2px, 0 2px;
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s;
  text-decoration: none;

  &:hover {
    background-position: bottom right;
    background-size: 0 2px, 100% 2px;
  }
`;

export const StyledLink = styled(Link)`
  white-space: nowrap;
  display: flex;

  ${genTextStyle({
    maxFontSize: 36,
    minFontSize: 22,
    maxLineHeight: 39.6,
    minLineHeight: 24,
  })}

  width: fit-content;
  background: linear-gradient(to right, transparent, transparent),
    linear-gradient(to right, black, black);
  background-size: 100% 2px, 0 2px;
  background-position: bottom left;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s;
  text-decoration: none;

  &:hover {
    background-position: bottom right;
    background-size: 0 2px, 100% 2px;
  }
`;

export const StyledCompanyInfo = styled.div<{ mode: "light" | "dark" }>`
  display: flex;
  flex-direction: row;
  gap: 20px;

  ${genTextStyle({
    maxFontSize: 24,
    minFontSize: 14,
    maxLineHeight: 28.8,
    minLineHeight: 16.8,
  })}

  div {
    color: ${({ mode, theme }) => mode === "dark" ? theme.colors.black : theme.colors.white};
  }

  span {
    color: ${({ mode, theme }) => mode === "dark" ? theme.colors.black : theme.colors.white};
  }

  @media all and (max-width: ${sizes.mediumS}) {
    flex-direction: column-reverse !important;
    align-items: flex-start;
    gap: 4px;
  }
`;

export const SocialIconWrapper = styled.div`
  white-space: nowrap;
`;
