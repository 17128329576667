import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { animated, useSpring } from "@react-spring/web";

import { PHILOSOPHY_PARAGRAPH_DELAY } from "utility/constants";

import {
  StyledListContainer,
} from "./companyDottedListStyle";
import { ColumnContainer } from "src/styles/globalStyle";

const defaultAnimationProps = {
  from: { opacity: 0 },
  to: { opacity: 1 },
  config: { duration: 1000 },
  reset: false,
};

type CompanyDottedListAnimatedProps = {
  list: Array<string>;
};

const CompanyDottedListAnimated = ({ list }: CompanyDottedListAnimatedProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;

  const dotSize = isMediumScreen ? 16 : 10;

  const animProps = [
    ...Array(list.length).keys()]
      .map((i) => (useSpring({ ...defaultAnimationProps, delay: PHILOSOPHY_PARAGRAPH_DELAY + i * PHILOSOPHY_PARAGRAPH_DELAY, })));

  return (
    <ColumnContainer $gap={16}>
      {animProps.map((props, i) => (
        <animated.div style={props} key={list[i]}>
          <StyledListContainer>
            <div
              style={{
                width: `${dotSize}px`,
              }}
            >
              <svg
                width={dotSize}
                height={dotSize}
                viewBox={`0 0 ${dotSize} ${dotSize}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx={dotSize / 2} cy={dotSize / 2} r={dotSize / 2} fill="black" />
              </svg>
            </div>
            <p>{list[i]}</p>
          </StyledListContainer>
        </animated.div>
      ))}
    </ColumnContainer>
  );
}

export default React.memo(CompanyDottedListAnimated);
