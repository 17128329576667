import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import buttonTheme, { ButtonType, theme } from "utility/theme";
import { ArrowType, customPadding } from "utility/models";
import { isTouchDevice } from "utility/functions";

import {
  StyledButtonMainContainer,
  StyledButtonMainContainerHover,
  StyledInnerButtonContainer,
  LoaderWrapper,
} from "./buttonStyle";

const Loader = ({ fill = theme.colors.black }: { fill?: string }): JSX.Element => {
  return (
    <svg
      version="1.1"
      id="loader-1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="50px"
      height="50px"
      viewBox="0 0 40 40"
      enableBackground="new 0 0 40 40"
      xmlSpace="preserve"
    >
      <path
        opacity="0.2"
        fill={fill}
        d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
      />
      <path
        fill={fill}
        d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
C22.32,8.481,24.301,9.057,26.013,10.047z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 20 20"
          to="360 20 20"
          dur="0.9s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

interface ButtonProps {
  text: string;
  height?: number | string;
  marginBottom?: number | string;
  type?: ButtonType;
  positionsArrow?: boolean;
  arrowType?: ArrowType | null;
  fontSize?: number;
  mobileFontSize?: number;
  justifyContent?: string;
  onClick?: any;
  centerContent?: boolean;
  lineHeight?: number;
  disabled?: boolean;
  loading?: boolean;
  iconInFront?: boolean;
  gridGap?: string;
  customPadding?: customPadding;
  isfullwidth?: boolean;
}

const Button = ({
  marginBottom = 0,
  text,
  type = ButtonType.TRANSPARENT,
  positionsArrow = false,
  arrowType = "right",
  fontSize = 30,
  mobileFontSize = 28,
  justifyContent = "space-between",
  onClick,
  centerContent = true,
  disabled = false,
  loading = false,
  iconInFront = false,
  customPadding,
  isfullwidth,
}: ButtonProps): JSX.Element => {
  const [hovered, setHovered] = useState<boolean>(false);
  const buttonThemeType = buttonTheme(type);

  const breakpoints = useBreakpoint();
  const isSmallScreen = !breakpoints.s;

  const arrowIcon: JSX.Element = (
    <>
      {arrowType === "right" ? (
        <svg
          style={{ minWidth: "35px" }}
          width="35"
          height="22"
          viewBox="0 0 35 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.4469 11.7131L21.6049 20.5744L22.9385 22.0001L34.1635 10.7371L34.1614 10.7352L22.8076 0L21.474 1.42566L30.4409 9.7609H0V11.7131H30.4469Z"
            fill={hovered ? buttonThemeType.hoverColor : buttonThemeType.color}
          />
        </svg>
      ) : null}
      {arrowType === "down" ? (
        <svg
          style={{ marginTop: "5px", minWidth: "35px" }}
          width="35"
          height="22"
          viewBox="0 0 35 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18.1606L20.5744 9.60494L22.0001 10.9385L10.7371 22.1635L10.7353 22.1614L0 10.8076L1.42566 9.47402L10 18.6981L10 0L12 0L12 18.1606Z"
            fill={hovered ? buttonThemeType.hoverColor : buttonThemeType.color}
          />
        </svg>
      ) : null}
      {arrowType === "up" ? (
        <svg
          style={{ marginTop: "5px", minWidth: "35px" }}
          width="35"
          height="22"
          viewBox="0 0 35 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4.00349L20.5744 12.5591L22.0001 11.2255L10.7371 0.000537872L10.7353 0.00264931L0 11.3564L1.42566 12.69L10 3.46597L10 22.1641H12L12 4.00349Z"
            fill={hovered ? buttonThemeType.hoverColor : buttonThemeType.color}
          />
        </svg>
      ) : null}
      {arrowType === "left" ? (
        <svg
          style={{ minWidth: "35px" }}
          width="35"
          height="22"
          viewBox="0 0 35 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M 5.3394 12.5 L 13.8951 21.0744 L 12.5615 22.5001 L 1.3365 11.2371 L 1.3386 11.2353 L 12.6924 0.5 L 14.026 1.9257 L 4.8019 10.5 H 34 V 12.5 H 5.3394 Z"
            fill={hovered ? buttonThemeType.hoverColor : buttonThemeType.color}
          />
        </svg>
      ) : null}
    </>
  );

  const button: JSX.Element = (
    <StyledButtonMainContainer
      onMouseEnter={() => !isTouchDevice && setHovered(true)}
      onMouseLeave={() => !isTouchDevice && setHovered(false)}
      onClick={() => onClick && !disabled && !loading && onClick()}
      onTouchEnd={() => isTouchDevice && setHovered(false)}
      data-margin-bottom={marginBottom}
      theme={buttonThemeType}
      type="submit"
      data-loading={loading && type === ButtonType.SUBSCRIBE}
      data-nontransparent={type !== ButtonType.TRANSPARENT}
      data-disabled={disabled || loading}
      disabled={disabled || loading}
      data-isfullwidth={isfullwidth}
      data-testid="button"
    >
      <StyledInnerButtonContainer
        $fontSize={fontSize}
        data-mobile-font-size={mobileFontSize}
        $justifyContent={justifyContent}
        $customPadding={customPadding}
      >
        {arrowType !== null &&
          arrowType !== "down" &&
          !isSmallScreen &&
          centerContent &&
          justifyContent !== "space-between" && <div />}

        <>
          {iconInFront ? (
            <>
              {arrowIcon}
              <span
                style={{
                  marginBottom: arrowType !== null ? 2 : 0,
                  whiteSpace: "pre-wrap",
                }}
                data-testid="button-text-with-icon-in-font"
              >
                {text}
              </span>
            </>
          ) : (
            <>
              <span
                style={{
                  marginBottom: arrowType !== null ? 2 : 0,
                  textAlign: arrowType !== null ? "left" : "center",
                  whiteSpace: "pre-wrap",
                  justifyContent: justifyContent
                  }}
                  data-testid="button-text-without-icon-in-font"
              >
                {text}
              </span>
              {arrowIcon}
            </>
          )}
        </>
        {loading && type === ButtonType.SUBSCRIBE && (
          <LoaderWrapper absolute>
            <Loader fill={theme.colors.white} />
          </LoaderWrapper>
        )}
      </StyledInnerButtonContainer>
    </StyledButtonMainContainer>
  );

  return (loading && type !== ButtonType.SUBSCRIBE) ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : !disabled && !loading ? (
    <StyledButtonMainContainerHover
      theme={buttonThemeType}
      data-nontransparent={type !== ButtonType.TRANSPARENT}
      data-loading={loading && type === ButtonType.SUBSCRIBE}
      data-isfullwidth={isfullwidth}
    >
      {button}
    </StyledButtonMainContainerHover>
  ) : (
    button
  );
};

export default React.memo(Button);
