import styled from "styled-components";

import { sizes } from "utility/device";
import { ButtonType } from "utility/theme";
import { customPadding } from "utility/models";
import { genTextStyle } from "src/styles/globalStyle";

export const StyledButtonMainContainer = styled.button<{
  "data-margin-bottom"?: number | string;
  theme?: ButtonType;
  "data-nontransparent"?: boolean;
  disabled?: boolean;
  "data-loading"?: boolean;
  "data-isfullwidth"?: boolean;
}>`
  max-height: min-content;
  box-sizing: border-box;
  display: flex;
  margin-bottom: ${({ "data-margin-bottom": marginBottom }) => marginBottom};
  font-weight: 400;
  ${({ "data-isfullwidth": isfullwidth }) => isfullwidth && `
    flex: 1;
    width: 100%;
  `}

  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ disabled, theme, "data-loading": loading }) => loading ? "transparent" : disabled ? (theme?.disabledColor ?? theme.color) : theme.color};
  transform: rotate(180deg);

  background-size: 100% 0;
  transition: 0.3s;

  border-radius: 200px;
  border: 2px solid ${({ theme }) => theme.borderColor};

  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  opacity: ${({ disabled, theme }) => (disabled ? theme.disabledOpacity : "1")};

  align-items: center;
  justify-content: space-between;
  position: relative;

  overflow: hidden;
  z-index: 0.9;

  @media (max-width: ${sizes.s}) {
    width: 100%;
    min-width: unset;
  }
`;

export const StyledButtonMainContainerHover = styled.div<{
  theme: ButtonType;
  "data-nontransparent"?: boolean;
  "data-loading"?: boolean;
  "data-isfullwidth"?: boolean;
}>`
  overflow: hidden;
  max-height: min-content;
  ${({ "data-isfullwidth": isfullwidth }) => isfullwidth && `
    width: 100%;
    flex: 1;
  `}

  @media (hover: hover) and (pointer: fine) {
    ${StyledButtonMainContainer}::before {
      ${({ "data-nontransparent": nontransparent, theme, "data-loading": loading }) => `
        background-color: ${(nontransparent || loading) ? theme.backgroundColor : theme.hoverBackgroundColor};
        height: ${nontransparent ? "100%" : "0px"};
        ${nontransparent ? "bottom: 0;" : "top: 0;"}
      `}
      width: 105%;
      left: -2.5%;
      content: "";
      z-index: -1;
      position: absolute;
      transition: 0.3s;
      border-radius: 200px;
      pointer-events: none;
    }

    ${StyledButtonMainContainer}:hover::after {
      content: '';
      position: absolute;
      top: -5px;
      bottom: -5px;
      left: -5px;
      right: -5px;
      border-radius: 205px;
      background: transparent;
      pointer-events: none;
    }
    
    ${StyledButtonMainContainer}:hover::before {
      ${({ "data-nontransparent": nontransparent }) => `
        height: ${nontransparent ? "0px" : "100%"};
      `}
    }
    ${StyledButtonMainContainer}:hover {
      color: ${({ theme, "data-loading": loading }) => loading ? "transparent" : theme.hoverColor};
      background-color: ${({ theme, "data-loading": loading }) => loading ? theme.backgroundColor : theme.hoverBackgroundColor};
    }
  }

  @media (pointer: coarse) {
    ${StyledButtonMainContainer}:before {
      background-color: ${({ theme }) => theme.backgroundColor};
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
      position: absolute;
      left: 0;
      transition: 0.3s;
      ${({ "data-nontransparent": nontransparent }) => `
        ${nontransparent ? "bottom: 0;" : "top: 0;"}
      `}
    }
  }
`;

export const StyledInnerButtonContainer = styled.span<{
  $fontSize: number;
  "data-mobile-font-size": number;
  $justifyContent?: string;
  $customPadding?: customPadding;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  position: relative;

  border-radius: 200px;

  width: 100%;
  justify-content: ${({ $justifyContent }) =>
    `${$justifyContent || "space-between"}`};

  font-family: Moderat-Regular;
  transform: rotate(180deg);

  padding: 12px 32px;

  ${({ $customPadding }) =>
          $customPadding?.paddingTopBottomMobile &&
    `
      padding-top: ${$customPadding?.paddingTopBottomMobile}px;
      padding-bottom: ${$customPadding?.paddingTopBottomMobile}px;
    `}

    ${({ $customPadding }) =>
            $customPadding?.paddingLeftRightMobile &&
      `
      padding-left: ${$customPadding?.paddingLeftRightMobile}px;
      padding-right: ${$customPadding?.paddingLeftRightMobile}px;
    `}

  ${({ $fontSize, "data-mobile-font-size": mobileFontSize }) => $fontSize && `
    ${genTextStyle({
      maxFontSize: $fontSize,
      minFontSize: mobileFontSize,
      maxLineHeight: $fontSize,
      minLineHeight: mobileFontSize,
      isCustomised: true,
    })}
  `};
  

  @media (max-width: ${sizes.s}) {
    padding: 12px 32px;
    font-size: ${({ "data-mobile-font-size": mobileFontSize }) => mobileFontSize}px;

    ${({ $customPadding }) =>
            $customPadding?.paddingTopBottomMobile &&
      `
      padding-top: ${$customPadding?.paddingTopBottomMobile}px;
      padding-bottom: ${$customPadding?.paddingTopBottomMobile}px;
    `}

    ${({ $customPadding }) =>
            $customPadding?.paddingLeftRightMobile &&
      `
      padding-left: ${$customPadding?.paddingLeftRightMobile}px;
      padding-right: ${$customPadding?.paddingLeftRightMobile}px;
    `}
    gap: 10px;
  }
`;

export const LoaderWrapper = styled.div<{ absolute?: boolean }>`
  ${({ absolute }) => absolute ? "position: absolute;" : "padding: 9px 32px;"}
`;
