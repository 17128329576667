import React, { useEffect, useState } from 'react';

import { cursorPosition } from 'src/types/mouse.types'

import {
  StyledDragIndicatorContainer,
} from './imageHoverIndicatorStyle';
import { isTouchDevice } from "utility/functions";

interface ImageHoverIndicatorProps {
  hidden: boolean;
  onMouseMove?: (position: cursorPosition) => void;
}

const ImageHoverIndicator = ({ hidden, onMouseMove }: ImageHoverIndicatorProps) => {
  const [position, setPosition] = useState({ x: -1, y: -1 });

  useEffect(() => {
    addEventListener('mousemove', mouseMoveTracker)
    return () => {
      removeEventListener('mousemove', mouseMoveTracker)
    }
  },[])

  const mouseMoveTracker = (event: MouseEvent) => {
    const {
      clientX = 0,
      clientY = 0,
    } = event || {}
    const position = { x: clientX, y: clientY }
    if (onMouseMove) {
      onMouseMove(position)
    }
    setPosition(position);
  }

  return (
      <StyledDragIndicatorContainer
        hidden={isTouchDevice || hidden}
        style={{ left: `${position.x}px`, top: `${position.y}px` }}
      >
        <div className="drag-spinner">
          <p>View<br />project</p>
        </div>
      </StyledDragIndicatorContainer>

  );
};

export default React.memo(ImageHoverIndicator);
