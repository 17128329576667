import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import { externalLinks } from "utility/lists";
import { ExternalLinkItem } from "utility/models";

import {
  SocialIconWrapper,
  StyledAClass,
  StyledCompanyInfo,
  StyledExternalLinks,
  StyledBottomContainer,
  StyledLogoSmall,
  StyledBottomTextContainer,
} from "./footerStyle";
import ArrowOutRightSVG from "src/svg/arrowOutRight";
import { theme } from "utility/theme";
import LogoSVG from "src/svg/logo";

interface FooterBottomMobileProps {
  mode?: "light" | "dark";
}

const FooterBottomMobile = ({ mode = "dark" }: FooterBottomMobileProps): JSX.Element => {
  const breakpoints = useBreakpoint();
  const isMediumScreen = breakpoints.m;
  const isNarrow = breakpoints.m;
  const currentYear = new Date().getFullYear();

  return (
    <StyledBottomContainer>
      <StyledExternalLinks mode={mode}>
        {externalLinks.map((item: ExternalLinkItem) => (
          <SocialIconWrapper key={item.title}>
            <StyledAClass
              href={item.link}
              rel="noreferrer"
              aria-label={item.title}
              target="_blank"
              mode={mode}
            >
              {item.title}&ensp;
              <ArrowOutRightSVG
                width={isMediumScreen ? 20 : 13}
                height={isMediumScreen ? 20 : 13}
                color={mode === "dark" ? theme.colors.black : theme.colors.white}
              />
            </StyledAClass>
          </SocialIconWrapper>
        ))}
      </StyledExternalLinks>
      <StyledBottomTextContainer>
        <StyledCompanyInfo mode={mode}>
          <span style={{ opacity: 0.3 }}>{`${currentYear} © LambdaWorks d.o.o.`}</span>
          <div>We build digital products.</div>
        </StyledCompanyInfo>
        <StyledLogoSmall $isHidden={isNarrow}>
          <LogoSVG color={mode === "dark" ? theme.colors.black : theme.colors.white} />
        </StyledLogoSmall>
      </StyledBottomTextContainer>
    </StyledBottomContainer>
  );
};

export default React.memo(FooterBottomMobile);
