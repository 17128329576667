import React from "react";
import {
  MainWrapper,
  NameMultipleSelect,
  Option,
  OptionInnerWrapper,
  OptionMultipleSelect,
  OptionName,
  OptionTriggerButton,
  RadioInputLabel,
  RadioInputStyled,
} from "./selectFieldStyle";
import { InternshipOptionList } from "utility/models";
import { theme } from "utility/theme";

interface SelectProps {
  selectOption: (filter: string) => void;
  optionName: string;
  isActive: boolean;
  highlightColor?: string;
}

const SelectMultipleFields = ({
  selectOption,
  optionName,
  isActive,
  highlightColor = theme.colors.yellowPastel,
}: SelectProps): JSX.Element => {
  return (
    <Option
      onClick={() => selectOption(optionName)}
      $isSelected={isActive}
      $isClickable
      $highlightColor={highlightColor}
    >
      <OptionInnerWrapper>
        <OptionName>{optionName}</OptionName>
        <OptionTriggerButton open={isActive}>
          <div />
          <div />
        </OptionTriggerButton>
      </OptionInnerWrapper>
    </Option>
  );
};

interface SelectSingleFieldProps {
  radioButtons: InternshipOptionList;
  selectOption: (e: any) => void;
  activeValue: string;
  groupName: string;
  highlightColor?: string;
}

export const SelectSingleField = ({
  selectOption,
  activeValue,
  radioButtons,
  groupName,
  highlightColor = theme.colors.yellowPastel,
}: SelectSingleFieldProps): JSX.Element => {
  return (
    <MainWrapper>
      {radioButtons.map((button) => (
        <React.Fragment key={button.value}>
          <RadioInputStyled
            type="radio"
            id={button.value}
            name={groupName}
            value={button.value}
            onClick={selectOption}
            disabled={button.disabled}
          ></RadioInputStyled>
          <RadioInputLabel htmlFor={button.value}>
            <OptionMultipleSelect
              $isSelected={activeValue === button.value}
              $isLightColor={button.disabled}
              $highlightColor={highlightColor}
            >
              <OptionInnerWrapper>
                <NameMultipleSelect>{button.value}</NameMultipleSelect>
              </OptionInnerWrapper>
            </OptionMultipleSelect>
          </RadioInputLabel>
        </React.Fragment>
      ))}
    </MainWrapper>
  );
};

export default SelectMultipleFields;
