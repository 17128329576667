import styled from "styled-components";
import { sizes } from "utility/device";
import { genCustomPropStyle, genTextStyle } from "src/styles/globalStyle";

export const LogoListContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${genCustomPropStyle({
    propName: "gap",
    maxSize: 120,
    minSize: 32,
  })}
`;


export const LogoListTitle = styled.div`
  ${genTextStyle({
    maxFontSize: 64,
    maxLineHeight: 76.8,
    minFontSize: 32,
    minLineHeight: 38.4,
  })}
`;

export const LogoWrapper = styled.div`
  max-width: 250px;
  max-height: 125px;
  svg {
    width: 100%;
    height: auto;

    min-width: 180px;
    min-height: 90px;
  }

  @media all and (max-width: ${sizes.m}) {
    svg {
      max-width: 200px;
      max-height: 100px;

      min-width: 100px;
      min-height: 50px;
    }
  }
`;

export const Wrapper = styled.div<{ $breakOnSmallDevices: boolean }>`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: center;
  align-items: center;
  gap: 80px;

  @media all and (max-width: ${sizes.m}) {
    gap: 32px;
    grid-template-columns: auto auto;
  }

  @media all and (max-width: ${({ $breakOnSmallDevices }) => `${$breakOnSmallDevices ? sizes.s : sizes.xs}`}) {
    grid-template-columns: auto;
  }
`;
