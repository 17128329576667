import React from "react";

import {
  StyledContainer,
  StyledMobileContainer,
  StyledMobileProjectContainer,
  StyledProjectDescription,
  StyledProjectImage,
  StyledProjectName,
  StyledTable,
  StyledTableContainer,
  StyledTexttContainer,
} from "./successStoriesStyled";
import Button from "components/Button/button";
import { navigate } from "gatsby";
import routes from "utility/routes";

type SuccessStory = {
  src: any;
  projectName: string;
  description: string;
  route: string;
}

type SuccessStoriesProps = {
  title: string;
  stories: [SuccessStory, SuccessStory, SuccessStory];
};

const SuccessStories = ({
  title,
  stories,
}: SuccessStoriesProps): JSX.Element => (
  <StyledContainer>
    <h2>{title}</h2>
    <StyledTableContainer>
      <StyledTable>
        <tr>
          <td>
            <StyledProjectImage url={stories[0].src} />
          </td>
          <td />
        </tr>

        <tr>
          <td style={{ verticalAlign: "top" }}>
            <StyledTexttContainer>
              <StyledProjectName>{stories[0].projectName}</StyledProjectName>
              <StyledProjectDescription>{stories[0].description}</StyledProjectDescription>
              <Button
                text="View Project"
                onClick={() => navigate(routes.SUPER_PHONE)}
              />
            </StyledTexttContainer>
          </td>
          <td>
            <StyledProjectImage url={stories[1].src} />
          </td>
        </tr>

        <tr>
          <td style={{ verticalAlign: "top", paddingTop: 60 }}>
            <StyledProjectImage url={stories[2].src} />
          </td>
          <td  style={{ verticalAlign: "top" }}>
            <StyledTexttContainer>
              <StyledProjectName>{stories[1].projectName}</StyledProjectName>
              <StyledProjectDescription $maxWidth={537}>{stories[1].description}</StyledProjectDescription>
              <Button
                text="View Project"
                onClick={() => navigate(routes.BEXIO)}
              />
            </StyledTexttContainer>
          </td>
        </tr>

        <tr>
          <td  style={{ verticalAlign: "top" }}>
            <StyledTexttContainer>
              <StyledProjectName>{stories[2].projectName}</StyledProjectName>
              <StyledProjectDescription>{stories[2].description}</StyledProjectDescription>
              <Button
                text="View Project"
                onClick={() => navigate(routes.TRADER_FYLES)}
              />
            </StyledTexttContainer>
          </td>
        </tr>
      </StyledTable>
    </StyledTableContainer>

    <StyledMobileContainer>
      {stories.map((item) => (
        <StyledMobileProjectContainer key={`${item.projectName}${item.src}`}>
          <StyledProjectImage url={item.src} />
          <StyledProjectName>{item.projectName}</StyledProjectName>
          <StyledProjectDescription>{item.description}</StyledProjectDescription>
          <Button
            text="View Project"
            mobileFontSize={24}
            onClick={() => navigate(item.route)}
          />
        </StyledMobileProjectContainer>
      ))}
    </StyledMobileContainer>

  </StyledContainer>
);

export default SuccessStories;
