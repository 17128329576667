import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import {
  StyledHeroParagraphContainer,
  StyledHeroParagraphText,
} from "./heroParagraphStyled";

type HeroParagraphProps =
  ({ heading: string; text?: string }
  | { text: string; heading?: string; }) & {
  reverse?: boolean;
  maxWidth?: number;
};

const HeroParagraph = ({
  heading,
  reverse = false,
  text,
  maxWidth = 1520,
}: HeroParagraphProps): JSX.Element => {
  return (
    <StyledHeroParagraphContainer $maxWidth={maxWidth}>
      {!heading ? (
        <h4>{text}</h4>
      ) : (
        <>
        {!reverse ? (
          <>
            <h4>{heading}</h4>
            {!!text && (
              <StyledHeroParagraphText>{text}</StyledHeroParagraphText>
            )}

          </>
        ) : (
          <>
            {!!text && (
              <StyledHeroParagraphText>{text}</StyledHeroParagraphText>
            )}
            <h4>{heading}</h4>
          </>
        )}

        </>
      )}

    </StyledHeroParagraphContainer>
  );
};

export default HeroParagraph;
