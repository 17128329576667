import styled from "styled-components";

import { sizes } from "utility/device";

export const StyledMainContainer = styled.div<{
  $alignItems: string
}>`
  display: flex;
  flex-direction: row;

  align-items: ${({ $alignItems }) => $alignItems ?? "flex-start"};

  width: 100%;

  cursor: pointer;
`;

export const StyledTextContainer = styled.h6<{ $isSelected: boolean }>`
  opacity: ${({ $isSelected }) => $isSelected ? "1" : "0.3"};
`;
